/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@include mat.core();
@import './assets/styles/fonts';
@import './assets/styles/variable';
@import './assets/styles/app';
@import './assets/styles/pages';
@import './assets/styles/spinner';
@import './assets/styles/material/expansion-panel';
@import './assets/styles/material/form';
@import './assets/styles/material/button';
@import './assets/styles/material/card';
@import './assets/styles/material/tab';
@import './assets/styles/material/fab';
@import './assets/styles/material/table';
@import './assets/styles/material/list';
@import './assets/styles/material/dialog';
@import './assets/styles/material/tooltip';
@import './assets/styles/layout';
@import './assets/styles/theme';
.toast-top-left-margin {
    top: 12px;
    left: 335px;
}

.toast-bottom-left-margin {
    bottom: 12px;
    left: 335px;
}

.toast-info {
    background: rgba(255, 255, 255, 0.9) !important;
    color: #4A4A4A !important;
    .toast-close-button {
        color: black;
    }
}

.toast-container {
    font-size: 0.9em;
    letter-spacing: -0.1px;
    font-family: "Proxima Nova";
    ul {
        padding: 0 0 0 20px;
        margin: 0;
    }
}

.toast-container .ngx-toastr {
    width: 600px !important;
    padding: 15px !important;
    transition: all 0.2s ease-in-out;
    &:hover {
        box-shadow: 0 0 12px #00000060 !important;
    }
}
